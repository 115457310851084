// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons"; // import brand icons here

export default function Footer() {
    return (
        <footer className="zaurax-footer">
            <h3>Contact:</h3>
            <a href="contact@zaurax.com">
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
            </a>
            <a href="https://youtube.com/c/toadie" target="_blank">
                <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
            </a>
        </footer>
    )
}
