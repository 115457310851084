import Slider from "../Slider";
import "./Home.css";

export default function Home() {
    return (
        <>
            <Slider />
        </>
    )
}
