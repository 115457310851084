import React, {useState, useRef, useEffect} from "react";
import { Carousel } from "react-responsive-carousel";

import "./carousel.css";

import cryus_rect_img from "./assets/cryus_rectangle.png";
import acrux_img from "./assets/acrux.png";
import placeholder_img from "./assets/placeholder.png";

// NOTE: Add pages to here
const data = [
    {
        title: "page 1", description: "descritpsiadknawd", img: cryus_rect_img,
    },
    {
        title: "page 2", description: "descritpsiadknawd", img: acrux_img,
    },
    {
        title: "page 3", description: "descritpsiadknawd", img: placeholder_img,
    },
    {
        title: "page 3", description: "cum descrip", img: placeholder_img,
    }
]

const Slider = () => {
    const [slide, setSlide] = useState(0);

    useEffect(() => {
        const s = setTimeout(() => {
            setSlide(slide => (slide + 1) % data.length)
        }, 5000);
        
        return () => {
            clearTimeout(s)
        }
    })

    return (
        <div className="slider-div">
            <Carousel onChange={(index) => setSlide(index)} selectedItem={slide} className="home-slider" showThumbs={false} showStatus={false} infiniteLoop swipeable emulateTouch autoPlay={false}>
                {
                        data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <img src={ item.img } height="300px" width="200px"/>
                                </div>
                            )
                        })
                }
            </Carousel>
            <div className="CText">
                <h1>{data[slide].title ?? "no title"}</h1>
                <p>{data[slide].description ?? "no description"}</p>

                <div>
                    {
                        data.map((item, index) => {
                            return (
                                <button class="Sdot" onClick={() => setSlide(index)}></button>
                            )
                        })
                    }
                </div>  
            </div>
        </div>
    );
};

export default Slider;
