import React from "react";
import { Route, Routes } from "react-router-dom";

import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import Contact from "./pages/Contact";

/* TODO:
react-touch-carousel: https://xiaody.github.io/react-touch-carousel/docs/cd/index.html
^would be nice for soundtrack stuff
*/

function App() {
    return (
        <>
            <Navbar />
            <div className="page-body-container">
                <div className="page-body">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Projects" element={<Projects />} />
                        <Route path="/About" element={<About />} />
                        <Route path="/Contact" element={<Contact />} />
                    </Routes>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default App;
